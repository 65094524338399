export default {
  JWT_TOKEN: "JWT_TOKEN",
  AUTH_TOKEN: "AUTH_TOKEN",
  RETURN_PATH: "RETURN_PATH",
  UID: "UID",
  SELECTED_CAM_IDS: "SELECTED_CAM_IDS",
  SELECTED_GROUP: "SELECTED_GROUP",
  SELECTED_VIEW: "SELECTED_VIEW",
  CURRENT_GROUP: "CURRENT_GROUP",
  CALCULATED_NUM: "CALCULATED_NUM",
  USER_LOGIN: "USER_LOGIN",
  ACTIVE_PARENT_ID: "ACTIVE_PARENT_ID",
  ALL_SELECTED_GROUPS: "ALL_SELECTED_GROUPS",
  ALL_OPENED_GROUPS: "ALL_OPENED_GROUPS",
  FULL_SELECTED_GROUPS: "FULL_SELECTED_GROUPS",
  SUBMENU_OPTIONS: "SUBMENU_OPTIONS",
  HAS_SEEN_COPYRIGHT_BANNER: "HAS_SEEN_COPYRIGHT_BANNER",
  USE_MSE_PLAYER: "USE_MSE_PLAYER",
  VOLUME_LEVEL: "VOLUME_LEVEL",
  LAST_VISIT_AD_BANNER: "LAST_VISIT_AD_BANNER",
  CAROUSEL_WILL_RELOAD: "CAROUSEL_WILL_RELOAD",
  CAROUSEL_RELOAD_STATUS: "CAROUSEL_RELOAD_STATUS",
  CURRENT_PATH_MODAL_CAMLIST: "CURRENT_PATH_MODAL_CAMLIST",
  LAST_MOBILE_AUTH_CODE_REQUEST: "LAST_MOBILE_AUTH_CODE_REQUEST",
  LAST_AUTH_METHOD: "LAST_AUTH_METHOD",
  CAMERA_EVENTS_SETTINGS: "CAMERA_EVENTS_SETTINGS",
};
